import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrderRoutingModule } from './order-routing.module';
import { OrderAddComponent } from './order-add/order-add.component';
import { OrderListComponent } from './order-list/order-list.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AgGridModule} from 'ag-grid-angular'
import { NgxDropzoneModule } from 'ngx-dropzone'
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { OrderChangedUnitComponent } from './order-changed-unit/order-changed-unit.component'
import {AutocompleteLibModule} from 'angular-ng-autocomplete';


@NgModule({
  declarations: [
    OrderAddComponent,
    OrderListComponent,
    OrderDetailComponent,
    OrderChangedUnitComponent
  ],
  imports: [
    CommonModule,
    OrderRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    AgGridModule,
    NgxDropzoneModule,
    AutocompleteLibModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class OrderModule { }
