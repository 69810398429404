<div class="col-xl">
    <form #myForm="ngForm" [formGroup]="serviceForm" id="formService" (ngSubmit)="addService(myForm)">
        <div class="card mb-4">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h5 class="mb-0">Datos de la solicitud</h5>
            </div>
            <div class="card-body">
                <div class="mb-3">
                    <div class="row">
                        <div class="col-lg-4 col-lg-4 col-xs-12">
                            <label class="form-label" for="basic-default-company">Cliente*</label>
                            <div class="input-group input-group-merge">

                                <select class="form-select" (change)="onSelectChange($event)" formControlName="client"
                                    [(ngModel)]="formModelData.client"
                                    [ngClass]="serviceForm.controls['client'].errors  && !serviceForm.controls['client'].pristine && submitted ? 'is-invalid' : ''">
                                    <option value="" selected>Seleccione un opción</option>
                                    <option [value]="item.id" *ngFor="let item of dataClient">{{item.name}}</option>
                                </select>
                                <div class="invalid-feedback"
                                    *ngIf="serviceForm.controls['client'].errors  && !serviceForm.controls['client'].pristine && submitted">
                                    El campo cliente es obligatorio
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <div class="row">
                        <div class="col-lg-6 col-md-4 col-xs-12">
                            <div class="row">
                                <div class="col-lg-10">
                                    <label class="form-label" for="basic-default-company">Origen*</label>
                                    <div class="input-group input-group-merge">
                                        <select *ngIf="clientSelected.origin_address.length == 0 && !changed_label_origin" class="form-select"
                                            formControlName="origin" [(ngModel)]="formModelData.origin"
                                            [ngClass]="serviceForm.controls['origin'].errors  && !serviceForm.controls['origin'].pristine && submitted ? 'is-invalid' : ''">
                                            <option value="" selected>Seleccione un opción</option>

                                        </select>

                                        <select *ngIf="clientSelected.origin_address.length && !changed_label_origin" class="form-select"
                                            formControlName="origin" [(ngModel)]="formModelData.origin"
                                            [ngClass]="serviceForm.controls['origin'].errors  && !serviceForm.controls['origin'].pristine && submitted ? 'is-invalid' : ''">
                                            <option value="" selected>Seleccione un opción</option>
                                            <option
                                                [value]="item.street+' '+item.number + ' ' + item.cologne + ', '+ item.postal_code + ' ' + item.stated"
                                                *ngFor="let item of clientSelected.origin_address">{{item.street}}
                                                {{item.number}} {{item.cologne}}, {{item.postal_code}} {{item.stated}}
                                            </option>

                                        </select>
                                        <input class="form-control" id="origin_address" type="text" *ngIf="changed_label_origin" formControlName="origin" [(ngModel)]="formModelData.origin" type="text"
                                            [ngClass]="serviceForm.controls['origin'].errors  && !serviceForm.controls['origin'].pristine && submitted ? 'is-invalid' : '' ">

                                        <div class="invalid-feedback"
                                            *ngIf="serviceForm.controls['origin'].errors  && !serviceForm.controls['origin'].pristine && submitted">
                                            El campo origen es obligatorio
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <br>
                                    <button type="button" class="btn btn-info" (click)="autocomple_origin() ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-repeat" viewBox="0 0 16 16">
                                            <path d="M11 5.466V4H5a4 4 0 0 0-3.584 5.777.5.5 0 1 1-.896.446A5 5 0 0 1 5 3h6V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192Zm3.81.086a.5.5 0 0 1 .67.225A5 5 0 0 1 11 13H5v1.466a.25.25 0 0 1-.41.192l-2.36-1.966a.25.25 0 0 1 0-.384l2.36-1.966a.25.25 0 0 1 .41.192V12h6a4 4 0 0 0 3.585-5.777.5.5 0 0 1 .225-.67Z"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>


                        </div>
                        <div class="col-lg-6 col-md-4 col-xs-12">
                            <div class="row">
                                <div class="col-lg-10">
                                    <label class="form-label" for="basic-default-company">Destino*</label>
                                    <div class="input-group input-group-merge">
                                        <select *ngIf="clientSelected.destination_address.length == 0 && !changed_label_destination" class="form-select"
                                            formControlName="destination" [(ngModel)]="formModelData.destination"
                                            [ngClass]="serviceForm.controls['destination'].errors  && !serviceForm.controls['destination'].pristine && submitted ? 'is-invalid' : ''">
                                            <option value="" selected>Seleccione un opción</option>
        
                                        </select>
                                        <select *ngIf="clientSelected.destination_address.length > 0 && !changed_label_destination" class="form-select"
                                            formControlName="destination" [(ngModel)]="formModelData.destination"
                                            [ngClass]="serviceForm.controls['destination'].errors  && !serviceForm.controls['destination'].pristine && submitted ? 'is-invalid' : ''">
        
                                            <option value="" selected>Seleccione un opción</option>
                                            <option
                                                [value]="item.street+' '+item.number + ' ' + item.cologne + ', '+ item.postal_code + ' ' + item.stated"
                                                *ngFor="let item of clientSelected.destination_address">{{item.street}}
                                                {{item.number}} {{item.cologne}}, {{item.postal_code}} {{item.stated}}</option>
        
                                        </select>

                                        <input class="form-control" id="destination_address" formControlName="destination" [(ngModel)]="formModelData.destination" type="text" *ngIf="changed_label_destination" 
                                            [ngClass]="serviceForm.controls['destination'].errors  && !serviceForm.controls['destination'].pristine && submitted ? 'is-invalid' : '' ">
                                        <div class="invalid-feedback"
                                            *ngIf="serviceForm.controls['destination'].errors  && !serviceForm.controls['destination'].pristine && submitted">
                                            El campo destino es obligatorio
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <br>
                                    <button type="button" class="btn btn-info" (click)="autocomple_destination()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-repeat" viewBox="0 0 16 16">
                                            <path d="M11 5.466V4H5a4 4 0 0 0-3.584 5.777.5.5 0 1 1-.896.446A5 5 0 0 1 5 3h6V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192Zm3.81.086a.5.5 0 0 1 .67.225A5 5 0 0 1 11 13H5v1.466a.25.25 0 0 1-.41.192l-2.36-1.966a.25.25 0 0 1 0-.384l2.36-1.966a.25.25 0 0 1 .41.192V12h6a4 4 0 0 0 3.585-5.777.5.5 0 0 1 .225-.67Z"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <div class="row" *ngFor="let item of formModelData.address; let i = index">
                        <div class="col-lg-6 col-md-6 col-xs-12">
                            <div class="row">
                                <div class="col-lg-10">
                                    <label class="form-label" for="basic-default-company">Origen*</label>
                                    <div class="input-group input-group-merge">
                                        <select *ngIf="clientSelected.origin_address.length == 0 && !item.input_origin" class="form-select" name="origin" [(ngModel)]="item.origin" [ngModelOptions]="{standalone: true}">
                                            <option value="" selected>Seleccione un opción</option>
                                        </select>

                                        <select *ngIf="clientSelected.origin_address.length && !item.input_origin" class="form-select" name="origin" [(ngModel)]="item.origin" [ngModelOptions]="{standalone: true}">
                                            <option value="" selected>Seleccione un opción</option>
                                            <option
                                                [value]="item.street+' '+item.number + ' ' + item.cologne + ', '+ item.postal_code + ' ' + item.stated"
                                                *ngFor="let item of clientSelected.origin_address">{{item.street}}
                                                {{item.number}} {{item.cologne}}, {{item.postal_code}} {{item.stated}}
                                            </option>

                                        </select>
                                        <input class="form-control" id="origin_address_{{i}}" type="text" *ngIf="item.input_origin" name="origin{{i}}" [(ngModel)]="item.origin" type="text" [ngModelOptions]="{standalone: true}">
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <br>
                                    <button type="button" class="btn btn-info" (click)="autocomple_origin_mult(i, item)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-repeat" viewBox="0 0 16 16">
                                            <path d="M11 5.466V4H5a4 4 0 0 0-3.584 5.777.5.5 0 1 1-.896.446A5 5 0 0 1 5 3h6V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192Zm3.81.086a.5.5 0 0 1 .67.225A5 5 0 0 1 11 13H5v1.466a.25.25 0 0 1-.41.192l-2.36-1.966a.25.25 0 0 1 0-.384l2.36-1.966a.25.25 0 0 1 .41.192V12h6a4 4 0 0 0 3.585-5.777.5.5 0 0 1 .225-.67Z"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>


                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12">
                            <div class="row">
                                <div class="col-lg-10">
                                    <label class="form-label" for="basic-default-company">Destino*</label>
                                    <div class="input-group input-group-merge">
                                        <select *ngIf="clientSelected.destination_address.length == 0 && !item.input_destination" class="form-select"
                                            name="destination{{i}}" [(ngModel)]="item.destination" [ngModelOptions]="{standalone: true}">
                                            <option value="" selected>Seleccione un opción</option>
        
                                        </select>
                                        <select *ngIf="clientSelected.destination_address.length > 0 && !item.input_destination" class="form-select"
                                            name="destination" [(ngModel)]="item.destination" [ngModelOptions]="{standalone: true}">
        
                                            <option value="" selected>Seleccione un opción</option>
                                            <option
                                                [value]="item.street+' '+item.number + ' ' + item.cologne + ', '+ item.postal_code + ' ' + item.stated"
                                                *ngFor="let item of clientSelected.destination_address">{{item.street}}
                                                {{item.number}} {{item.cologne}}, {{item.postal_code}} {{item.stated}}</option>
        
                                        </select>

                                        <input class="form-control" id="destination_address_{{i}}" name="destination" [(ngModel)]="item.destination" type="text" *ngIf="item.input_destination" [ngModelOptions]="{standalone: true}">
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <br>
                                    <button type="button" class="btn btn-info" (click)="autocomple_destination_mult(i, item)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-repeat" viewBox="0 0 16 16">
                                            <path d="M11 5.466V4H5a4 4 0 0 0-3.584 5.777.5.5 0 1 1-.896.446A5 5 0 0 1 5 3h6V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192Zm3.81.086a.5.5 0 0 1 .67.225A5 5 0 0 1 11 13H5v1.466a.25.25 0 0 1-.41.192l-2.36-1.966a.25.25 0 0 1 0-.384l2.36-1.966a.25.25 0 0 1 .41.192V12h6a4 4 0 0 0 3.585-5.777.5.5 0 0 1 .225-.67Z"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                        <label class="form-label" for="basic-default-company" style="position: absolute; top: 49%; right: 1%; width: 45px;"> 
                            <span style="float: right; cursor: pointer;" (click)="deleteAddress(i)"><i class="ti ti-trash"></i></span>
                        </label>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-xs-12 mt-3">
                            <label class="form-label" for="basic-default-company"> Agregar otra dirección</label>
                            <br>
                            <a (click)="addAddess()" style="margin-top: 5px; display: block; cursor: pointer; color: #00cfe8;" ><i class="ti ti-plus"></i> Agregar</a>
                        </div>
                    </div>
                    
                </div>
                <div>
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-xs-12">
                            <label class="form-label" for="basic-default-company">Unidad*</label>
                            <div class="input-group input-group-merge">
                                <select class="form-select" formControlName="unit" [(ngModel)]="formModelData.unit" (change)="changedValidUnified($event)"
                                    [ngClass]="serviceForm.controls['unit'].errors  && !serviceForm.controls['unit'].pristine && submitted ? 'is-invalid' : ''">
                                    <option value="" selected>Seleccione un opción</option>
                                    <option [value]="item.id" *ngFor="let item of dataUnit">{{item.unit}}</option>
                                </select>

                                <div class="invalid-feedback"
                                    *ngIf="serviceForm.controls['unit'].errors  && !serviceForm.controls['unit'].pristine && submitted">
                                    El campo unidad es obligatorio
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-4 col-md-4 col-xs-12">
                            <label class="form-label" for="basic-default-company">Operador*</label>
                            <div class="input-group input-group-merge">
                                <div class="ng-autocomplete">
                                    <ng-autocomplete 
                                        [(ngModel)]="formModelData.driver"
                                        [ngModelOptions]="{standalone: true}"
                                        [data]="dataDriver"
                                        [searchKeyword]="'name'"
                                        placeholder="Seleccione una opción"
                                        (selected)='selectEventDriver($event, 0)'
                                        (inputChanged)='onChangeSearchDriver($event)'
                                        (inputFocused)='onFocusedDriver($event)'
                                        [itemTemplate]="itemTemplate"
                                        [notFoundTemplate]="notFoundTemplate">                                 
                                    </ng-autocomplete>
                                    
                                    <ng-template #itemTemplate let-item>
                                    <a [innerHTML]="item.name"></a>
                                    </ng-template>
                                    
                                    <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>
                                <!-- <select *ngIf="!isUnified" class="form-select" formControlName="driver" [(ngModel)]="formModelData.driver"
                                    [ngClass]="serviceForm.controls['driver'].errors  && !serviceForm.controls['driver'].pristine && submitted ? 'is-invalid' : ''">

                                    <option value="" selected>Seleccione un opción</option>
                                    <option [value]="item.id" *ngFor="let item of dataDriver">{{item.name}}
                                        {{item.last_name}}</option>
                                </select> -->
                                <p *ngIf="isUnified">{{dataValidUnified.driver}}</p>
                                <div class="invalid-feedback"
                                    *ngIf="serviceForm.controls['driver'].errors  && !serviceForm.controls['driver'].pristine && submitted">
                                    El campo operador es obligatorio
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-xs-12">
                            <label class="form-label" for="basic-default-company">Auxiliar*</label>
                            <div class="input-group input-group-merge">
                                <div class="ng-autocomplete">
                                    <ng-autocomplete 
                                        [(ngModel)]="formModelData.assistant"
                                        [ngModelOptions]="{standalone: true}"
                                        [data]="dataAssistant"
                                        [searchKeyword]="'name'"
                                        placeholder="Seleccione una opción"
                                        (selected)='selectEventDriver($event, 1)'
                                        (inputChanged)='onChangeSearchDriver($event)'
                                        (inputFocused)='onFocusedDriver($event)'
                                        [itemTemplate]="itemTemplate"
                                        [notFoundTemplate]="notFoundTemplate">                                 
                                    </ng-autocomplete>
                                    
                                    <ng-template #itemTemplate let-item>
                                    <a [innerHTML]="item.name"></a>
                                    </ng-template>
                                    
                                    <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>
                                <!-- <select class="form-select" formControlName="assistant"
                                    [(ngModel)]="formModelData.assistant"
                                    *ngIf="!isUnified"
                                    [ngClass]="serviceForm.controls['assistant'].errors  && !serviceForm.controls['assistant'].pristine && submitted ? 'is-invalid' : ''">
                                    <option value="" selected>Seleccione un opción</option>
                                    <option [value]="item.id" *ngFor="let item of dataAssistant">{{item.name}} {{item.last_name}}</option>
                                </select> -->
                                <p *ngIf="isUnified">{{dataValidUnified.assistant}}</p>
                                <div class="invalid-feedback"
                                    *ngIf="serviceForm.controls['assistant'].errors  && !serviceForm.controls['assistant'].pristine && submitted">
                                    El campo auxiliar es obligatorio
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div >
                    <div class="row">
                        <div *ngFor="let item of formModelData.assistants; let i = index" class="col-lg-4 col-md-4 col-xs-12 mt-3">
                            <label class="form-label" for="basic-default-company" style="display: block;">Auxiliar* 
                                <span style="float: right; cursor: pointer;" (click)="deleteAssitant(i)"><i class="ti ti-x"></i></span></label>
                            <div class="input-group input-group-merge">
                                <div class="ng-autocomplete">
                                    <ng-autocomplete 
                                        [data]="dataAssistant"
                                        [searchKeyword]="'name'"
                                        placeholder="Seleccione una opción"
                                        (selected)='selectEventDriver($event, 2, i)'
                                        (inputChanged)='onChangeSearchDriver($event)'
                                        (inputFocused)='onFocusedDriver($event)'
                                        [itemTemplate]="itemTemplate"
                                        [notFoundTemplate]="notFoundTemplate">                                 
                                    </ng-autocomplete>
                                    
                                    <ng-template #itemTemplate let-item>
                                    <a [innerHTML]="item.name"></a>
                                    </ng-template>
                                    
                                    <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>
                                <!-- <select class="form-select" name="assistant" cc [ngModelOptions]="{standalone: true}">
                                    <option value="" selected>Seleccione un opción</option>
                                    <option [value]="item.id" *ngFor="let item of dataAssistant">{{item.name}} {{item.last_name}}</option>

                                </select>
                                <div class="invalid-feedback"
                                    *ngIf="serviceForm.controls['assistant'].errors  && !serviceForm.controls['assistant'].pristine && submitted">
                                    El campo auxiliar es obligatorio
                                </div> -->
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-xs-12 mt-3">
                            <label *ngIf="!isUnified" class="form-label" for="basic-default-company"> Agregar otro Auxiliar</label>
                            <a *ngIf="isUnified" style="margin-top: 5px; display: block; cursor: pointer; color: #00cfe8;" ><i class="ti ti-info-circle"></i>&ensp; Unidad Unificada</a>
                            <br>
                            
                            <a (click)="addAuxAssistant()" *ngIf="!isUnified" style="margin-top: 5px; display: block; cursor: pointer; color: #00cfe8;" ><i class="ti ti-plus"></i> Agregar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="card mb-4">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h5 class="mb-0">Fecha de la solicitud</h5>
            </div>
            <div class="card-body">
                <div class="mb-3">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-xs-12">
                            <label class="form-label" for="basic-default-company">Hora Cargue*</label>
                            <div class="input-group input-group-merge">

                                <input type="time" class="form-control"
                                    [ngClass]="serviceForm.controls['charging_hour'].errors  && !serviceForm.controls['charging_hour'].pristine && submitted ? 'is-invalid' : ''"
                                    formControlName="charging_hour" [(ngModel)]="formModelData.charging_hour" />

                                <div class="invalid-feedback"
                                    *ngIf="serviceForm.controls['charging_hour'].errors  && !serviceForm.controls['charging_hour'].pristine && submitted">
                                    El campo hora cargue es obligatorio
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-3 col-md-3 col-xs-12">
                            <label class="form-label" for="basic-default-company">Fecha Cargue*</label>
                            <div class="input-group input-group-merge">

                                <input type="date" class="form-control"
                                    [ngClass]="serviceForm.controls['upload_date'].errors  && !serviceForm.controls['upload_date'].pristine && submitted ? 'is-invalid' : ''"
                                    formControlName="upload_date" [(ngModel)]="formModelData.upload_date" />

                                <div class="invalid-feedback"
                                    *ngIf="serviceForm.controls['upload_date'].errors  && !serviceForm.controls['upload_date'].pristine && submitted">
                                    El campo fecha cargue es obligatorio
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-3 col-md-3 col-xs-12">
                            <label class="form-label" for="basic-default-company">Hora Descargue*</label>
                            <div class="input-group input-group-merge">

                                <input type="time" class="form-control"
                                    [ngClass]="serviceForm.controls['download_time'].errors  && !serviceForm.controls['download_time'].pristine && submitted ? 'is-invalid' : ''"
                                    formControlName="download_time" [(ngModel)]="formModelData.download_time" />

                                <div class="invalid-feedback"
                                    *ngIf="serviceForm.controls['download_time'].errors  && !serviceForm.controls['download_time'].pristine && submitted">
                                    El campo hora cargue es obligatorio
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-3 col-md-3 col-xs-12">
                            <label class="form-label" for="basic-default-company">Fecha Descargue*</label>
                            <div class="input-group input-group-merge">

                                <input type="date" class="form-control"
                                    [ngClass]="serviceForm.controls['download_date'].errors  && !serviceForm.controls['download_date'].pristine && submitted ? 'is-invalid' : ''"
                                    formControlName="download_date" [(ngModel)]="formModelData.download_date" />

                                <div class="invalid-feedback"
                                    *ngIf="serviceForm.controls['download_date'].errors  && !serviceForm.controls['download_date'].pristine && submitted">
                                    El campo hora cargue es obligatorio
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-4">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h5 class="mb-0">Equipo de seguiridad</h5>
            </div>
            <div class="card-body">
                <div class="mb-3">
                    <div class="row">
                        <div class="col-lg-4 col-lg-4 col-xs-12">
                            <label class="form-label" for="basic-default-company">Bandas*</label>
                            <div class="input-group input-group-merge">

                                <select class="form-select" formControlName="bands" [(ngModel)]="formModelData.bands"
                                    [ngClass]="serviceForm.controls['bands'].errors  && !serviceForm.controls['bands'].pristine && submitted ? 'is-invalid' : ''">
                                    <option value="" selected>Seleccione un opción</option>
                                    <option [value]="item" *ngFor="let item of safety_equipment">{{item}}</option>

                                </select>

                                <div class="invalid-feedback"
                                    *ngIf="serviceForm.controls['bands'].errors  && !serviceForm.controls['bands'].pristine && submitted">
                                    El campo bandas es obligatorio
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-4 col-lg-4 col-xs-12">
                            <label class="form-label" for="basic-default-company">Patines*</label>
                            <div class="input-group input-group-merge">

                                <select class="form-select" formControlName="roller_skates"
                                    [(ngModel)]="formModelData.roller_skates"
                                    [ngClass]="serviceForm.controls['roller_skates'].errors  && !serviceForm.controls['roller_skates'].pristine && submitted ? 'is-invalid' : ''">
                                    <option value="" selected>Seleccione un opción</option>
                                    <option [value]="item" *ngFor="let item of safety_equipment">{{item}}</option>

                                </select>

                                <div class="invalid-feedback"
                                    *ngIf="serviceForm.controls['roller_skates'].errors  && !serviceForm.controls['roller_skates'].pristine && submitted">
                                    El campo patines es obligatorio
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-4 col-lg-4 col-xs-12">
                            <label class="form-label" for="basic-default-company">Playos*</label>
                            <div class="input-group input-group-merge">

                                <select class="form-select" formControlName="beach" [(ngModel)]="formModelData.beach"
                                    [ngClass]="serviceForm.controls['beach'].errors  && !serviceForm.controls['beach'].pristine && submitted ? 'is-invalid' : ''">
                                    <option value="" selected>Seleccione un opción</option>
                                    <option [value]="item" *ngFor="let item of safety_equipment">{{item}}</option>

                                </select>

                                <div class="invalid-feedback"
                                    *ngIf="serviceForm.controls['beach'].errors  && !serviceForm.controls['beach'].pristine && submitted">
                                    El campo playos es obligatorio
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="mb-3">
                    <div class="row">
                        <div class="col-lg-4 col-lg-4 col-xs-12">
                            <label class="form-label" for="basic-default-company">Diablos*</label>
                            <div class="input-group input-group-merge">

                                <select class="form-select" formControlName="devils" [(ngModel)]="formModelData.devils"
                                    [ngClass]="serviceForm.controls['devils'].errors  && !serviceForm.controls['devils'].pristine && submitted ? 'is-invalid' : ''">
                                    <option value="" selected>Seleccione un opción</option>
                                    <option [value]="item" *ngFor="let item of safety_equipment">{{item}}</option>

                                </select>

                                <div class="invalid-feedback"
                                    *ngIf="serviceForm.controls['devils'].errors  && !serviceForm.controls['devils'].pristine && submitted">
                                    El campo diablos es obligatorio
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-4 col-lg-4 col-xs-12">
                            <label class="form-label" for="basic-default-company">Colchonetas*</label>
                            <div class="input-group input-group-merge">

                                <select class="form-select" formControlName="mats" [(ngModel)]="formModelData.mats"
                                    [ngClass]="serviceForm.controls['mats'].errors  && !serviceForm.controls['mats'].pristine && submitted ? 'is-invalid' : ''">
                                    <option value="" selected>Seleccione un opción</option>
                                    <option [value]="item" *ngFor="let item of safety_equipment">{{item}}</option>

                                </select>

                                <div class="invalid-feedback"
                                    *ngIf="serviceForm.controls['mats'].errors  && !serviceForm.controls['mats'].pristine && submitted">
                                    El campo colchonetas es obligatorio
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-4 col-lg-4 col-xs-12">
                            <label class="form-label" for="basic-default-company">Cartones*</label>
                            <div class="input-group input-group-merge">

                                <select class="form-select" formControlName="cartons"
                                    [(ngModel)]="formModelData.cartons"
                                    [ngClass]="serviceForm.controls['cartons'].errors  && !serviceForm.controls['cartons'].pristine && submitted ? 'is-invalid' : ''">
                                    <option value="" selected>Seleccione un opción</option>
                                    <option [value]="item" *ngFor="let item of safety_equipment">{{item}}</option>

                                </select>

                                <div class="invalid-feedback"
                                    *ngIf="serviceForm.controls['cartons'].errors  && !serviceForm.controls['cartons'].pristine && submitted">
                                    El campo cartones es obligatorio
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </div>

        <div class="card mb-4">
            <div class="card-body text-center">
                <button type="submit" class="btn btn-primary me-sm-3 me-1">Enviar</button>
            </div>
        </div>
    </form>
</div>

<div class="modal fade" id="modalValidUnified" tabindex="1" aria-hidden="true" data-bs-backdrop="static"
    data-bs-keyboard="false">
    <div class="modal-dialog modal-md modal-simple modal-refer-and-earn">
        <div class="modal-content p-1 p-md-1">
            <div class="modal-header d-block text-center">
                <h5 class="modal-title text-center">Unificar unidad</h5>
            </div>
            <div class="modal-body pb-1">
                <div class="text-center">
                    <p>
                        La unidad seleccionada se encuentra asignada a otro servicio solicitado<br>
                        <b>¿desea unificar esta unidad al servicio en proceso de creación o desea crear un nuevo servicio para esta unidad?</b>
                    </p>
                </div>
            </div>
            <div class="modal-footer d-block">
                <div class="text-center">
                    <button type="button" class="btn btn-outline-primary m-1" (click)="clickModalValidUnified(false)">Nuevo servicio</button>
                    <button type="button" class="btn btn-primary m-1" (click)="clickModalValidUnified(true)">Unificar unidad</button>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.7)" size="medium" color="#fff" type="ball-atom">
    <p style="font-size: 20px; color: white">{{text_loading}}</p>
</ngx-spinner>